var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.isSpaceAdmin &&
      _vm.isDistributedInstance &&
      _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE &&
      !_vm.isSpaceArchived
        ? _c(
            "v-banner",
            {
              staticClass: "mb-5",
              scopedSlots: _vm._u(
                [
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { target: "_blank", color: "primary" },
                            on: { click: _vm.goToMaster },
                          },
                          [_vm._v("Return to Master instance")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1387382373
              ),
            },
            [
              _c(
                "v-avatar",
                {
                  attrs: { slot: "icon", color: "white", size: "30" },
                  slot: "icon",
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "info" } }, [
                    _vm._v("info"),
                  ]),
                ],
                1
              ),
              _c("span", { staticClass: "subtitle-1" }, [
                _vm._v(
                  " You can recall files and apps distributed to all students or if you wish to make updates, then return to the Master instance and distribute the changed materials. "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.isSpaceArchived &&
      _vm.isSpaceAdmin &&
      !_vm.isDistributedInstance &&
      !_vm.isSpaceInAnyRestState
        ? _c(
            "v-alert",
            {
              staticClass: "rounded-0 mb-0",
              attrs: { text: "", color: "info" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("v-icon", { attrs: { color: "info", right: "" } }, [
                        _vm._v("info"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "grow" },
                    [
                      _vm._v(" This space is archived. You may "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            small: "",
                            color: "primary",
                            to: {
                              name: _vm.getCloneRoute,
                              oid: this.$route.oid,
                              query: {
                                sid: this.$route.params.sid,
                              },
                            },
                          },
                        },
                        [_vm._v(" create a new space ")]
                      ),
                      _vm._v(
                        " reusing the contents of the Master instance, download files or distribute files/tables/applications to other spaces. "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: "https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course",
                            target: "_blank",
                            color: "primary",
                          },
                        },
                        [_vm._v(" Learn more ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSpaceRested
        ? _c(
            "v-alert",
            {
              staticClass: "rounded-0 mb-0",
              attrs: { text: "", color: "info" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("v-icon", { attrs: { color: "info", right: "" } }, [
                        _vm._v("mdi-sleep"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "grow" }, [
                    _vm._v(
                      "The space is currently in resting state. Wake it up to work with your files."
                    ),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            href: _vm.restingDocLink,
                            target: "_blank",
                            color: "primary",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.documentationIcon)),
                          ]),
                          _vm._v(" documentation "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.wakeSpace()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-bell-outline"),
                          ]),
                          _vm._v(" Wake up "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSpaceResting
        ? _c(
            "v-alert",
            {
              staticClass: "rounded-0 mb-0",
              attrs: { text: "", color: "warning" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("v-icon", { attrs: { color: "warning", right: "" } }, [
                        _vm._v("mdi-sleep"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "grow" }, [
                    _vm._v("This space is being put to resting state."),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            href: _vm.restingDocLink,
                            target: "_blank",
                            color: "warning",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.documentationIcon)),
                          ]),
                          _vm._v(" documentation "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isSpacePreResting
        ? _c(
            "v-alert",
            {
              staticClass: "rounded-0 mb-0",
              attrs: { text: "", color: "info" },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c("v-icon", { attrs: { color: "info", right: "" } }, [
                        _vm._v("mdi-bell-sleep-outline"),
                      ]),
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "grow" }, [
                    _vm._v(
                      "This space will be put to resting state within 24 hours. Start an application to prevent putting to resting state."
                    ),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            href: _vm.restingDocLink,
                            target: "_blank",
                            color: "primary",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.documentationIcon)),
                          ]),
                          _vm._v(" documentation "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { height: "100%" } },
        [
          _c("TheSnapshotAppIframe"),
          _c("TheSnapshotFileList", {
            style:
              _vm.$route.name !== "snapshot-files"
                ? {
                    "z-index": -1,
                    opacity: 0,
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                  }
                : {},
          }),
          _c(
            "v-container",
            {
              style:
                _vm.$route.name === "snapshot-files" ||
                _vm.$router.name === "app-open"
                  ? {
                      "z-index": -1,
                      opacity: 0,
                      position: "fixed",
                      top: "0px",
                      left: "0px",
                    }
                  : {},
              attrs: { fluid: "" },
            },
            [
              _c("router-view", { attrs: { name: "notifications" } }),
              _c(
                "transition",
                { attrs: { name: "fadeonly", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    { attrs: { include: ["snapshot-tables"] } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isSpaceWaking
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500", persistent: "" },
              model: {
                value: _vm.wakingSpaceDialog,
                callback: function ($$v) {
                  _vm.wakingSpaceDialog = $$v
                },
                expression: "wakingSpaceDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v("mdi-bell-ring-outline"),
                      ]),
                      _vm._v(" Space is currently waking up "),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pt-3" }, [
                    _c("div", [
                      _vm._v(
                        " The space is currently waking up. This can take a couple of minutes depending on the amount of data stored in files. You'll get a notification when the space is completely awake. "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            to: {
                              name: "home-dashboard",
                              params: { oid: _vm.$route.params.oid },
                            },
                            text: "",
                          },
                        },
                        [_vm._v("Return to Dashboard")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            href: _vm.restingDocLink,
                            target: "_blank",
                            color: "primary",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.documentationIcon)),
                          ]),
                          _vm._v(" documentation "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }